import * as React from 'react';
import classnames from 'classnames';
import s from '../ProductImage.scss';
import {
  PhysicalProductDefaultImage,
  DefaultProductImage,
} from '@wix/wixstores-client-common-components/dist/es/src/icons/dist';

export enum DataHook {
  emptyImage = 'product-empty-image',
  oldEmptyImage = 'old-product-empty-image',
}

const DEFAULT_IMAGE_DEFAULT_SIZE = '50';

export interface IEmptyProductImageProps {
  horizontalLayout: boolean;
  extendOnHoverToggle: boolean;
  shouldUseNewDefaultProductImage?: boolean;
}

export const EmptyProductImage: React.FunctionComponent<IEmptyProductImageProps> = ({
  horizontalLayout,
  extendOnHoverToggle,
  shouldUseNewDefaultProductImage,
}) => {
  const renderDefaultProductImage = () => {
    if (shouldUseNewDefaultProductImage) {
      return (
        <div
          data-hook={DataHook.emptyImage}
          className={classnames(s.emptyImage, {
            [s.horizontal]: horizontalLayout,
            [s.vertical]: !horizontalLayout,
            [s.hiddenOverflow]: extendOnHoverToggle,
          })}>
          <div className={classnames(s.semiTransparent, s.centerContent, {[s.zoomOnHover]: extendOnHoverToggle})}>
            <DefaultProductImage size={DEFAULT_IMAGE_DEFAULT_SIZE} />
          </div>
        </div>
      );
    } else {
      return (
        <div
          data-hook={DataHook.oldEmptyImage}
          className={classnames(s.oldEmptyImage, {
            [s.horizontal]: horizontalLayout,
            [s.vertical]: !horizontalLayout,
            [s.hiddenOverflow]: extendOnHoverToggle,
          })}>
          <div className={classnames(s.semiTransparent, s.centerContent, {[s.zoomOnHover]: extendOnHoverToggle})}>
            <PhysicalProductDefaultImage size={DEFAULT_IMAGE_DEFAULT_SIZE} />
          </div>
        </div>
      );
    }
  };

  return renderDefaultProductImage();
};
